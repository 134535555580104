@font-face {
  font-family: 'Axiforma-Book';
  src: url('./public/fonts/Axiforma-Regular.ttf') format('truetype');
}
*{
  margin:0;
  padding:0;
}
body{
  overflow-y: scroll;
  font-family: 'Axiforma-Book' !important;
}

