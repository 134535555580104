.login{
    background: linear-gradient(#3164F4, #123494);
    
}
.animated_auth{
    -webkit-animation: scale-in-hor-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-right {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-hor-right {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
.login_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
}

.login_container form input{
 padding: 0.8rem;
}

.login_content_box{
    border-radius: 8px;
    /* border: 2px solid black; */
    background:white;
    box-sizing: border-box;
    padding: 1.5rem;
    margin-left: none !important;
 
   
}
.login_container_logo{
    text-align: center;
    padding: 0.5rem 1.5rem;
}
.signup_container_logo{
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;

}
.login_container_forgotpassword{
    text-align: right;
    color:#3164F4;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    cursor: pointer;
    padding-bottom:0.8rem;

}
.login_container_signup{
    color:#3164F4;
    cursor: pointer;

}
.login_container_text{
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}
.login_container_button{
    padding: 0.6rem 0px !important;
}
.gray_svg svg path{
fill:#656565;
}
.white_svg svg path{
 fill:#f3f3f3;
}
/* .nav_language{

} */

.login_container_errors{
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 11px;
  padding: 3px 10px;
  right: 20%;
  left: 20%;
  top: 1px;
  font-size: 12px
}

.form_errors{
  color: red;
  font-weight: 500;
  font-size: 12px;
  margin: 5px 2px;
}
.confirmation_page{
  height:100vh;
  background: linear-gradient(#3164F4, #123494);
  
}
.confirmation_card{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background:white;
  border-radius:8px;
  padding:20px 0px;
  width:450px;
}
:root {
  --toastify-icon-color-error: #ff0000 !important; /* Set your desired color here */
}
.Toastify__progress-bar--error{
  background-color: #ff0000 !important; /* Set your desired color here */
}
.Toastify__toast--error .Toastify__icon svg {
  fill: var(--toastify-icon-color-error) !important; /* Set your desired color here */
}

.confirmation_content{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation_text{
  font-size: 18px;
  font-weight: 500;
  color:#535353;
  width:70%;
  overflow: hidden;
  text-align: center;
  padding-top: 15px;

}
.enter_password_login{
  width:80%;
}

.enter_password_button{
  padding: 20px;
  margin-top:10px;
}

.enter_password_input{
  margin-bottom:15px;
}

.smaller_text{
  font-size: 12px;
  font-weight: 700;
  
}