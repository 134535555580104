.nav_text {
  font-size: 1rem;
  line-height: 22px;
  font-weight: 700;
}
.nav_navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.nav_header_icons {
  display: flex;
  align-items: center;
}

.sidebar_content {
  width: 13%;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  height: 91.5vh;
}
.sidebar_content_companyname {
  font-size: 1.2rem;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
}
.sidebar_content_profile_text {
  color: #3164f4;
  font-size: 0.9rem;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.sidbar_content_menu_text {
  font-size: 0.9rem;
  line-height: 22px;
  font-weight: 700;
}
.sidbar_content_menu_item {
  cursor: pointer;
  display: flex;
  margin-top: 10px;
}
.sidbar_content_menu_item:hover {
  background-color: rgba(49, 100, 244, 0.1);
  border-radius: 8px;
  color: #3164f4;
}

.sidbar_content_menu_item:hover svg path {
  fill: #3164f4;
}
.side_bar_active svg path {
  fill: #3164f4;
}

.sidbar_content_main_screen {
  width: 87%;
  background: rgba(87, 129, 245, 0.1);
}
.sidbar_content_orders {
  background: white;
  border-radius: 8px;
  margin-bottom: 15px;
  width: 100%;
}
@media (min-width: 576px) {
  .sidbar_content_orders {
    /* width: 32%; */
  }
}
@media (min-width: 576px) {
  .sidbar_content_main_screen col-sm-4 {
    /* flex: 0 0 auto; */
    /* width: 32.33333333% !important; */
  }
}
.sidbar_content_icons_round {
  height: 4.5rem;
  width: 4.5rem;
  /* background-color: red; */
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
}
.sidbar_content_icons_round svg {
  height: 27px;
  width: 27px;
}
.sidbar_content_icons_round svg path {
  fill: white;
}
.sidbar_content_container {
  padding: 10px 80px;
}
.sidbar_content_details_text {
  font-weight: 500;
  font-size: 20px;
}
.sidebar_content_table {
  border-radius: 10px;
  margin: 2rem -10px 20px;
  width: 67%;
}
.sidebar_content_table td,
th {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.sidbar_content_table_first_row th {
  border-right: none;
  border-bottom-width: 0px;
}
.sidebar_content_orders {
  font-size: 1.5rem;
  font-weight: 500;
}
.sidebar_content_view_all {
  font-size: 0.9rem;
  font-weight: 400;
  color: #3164f4;
  text-align: center;
  padding-right: 10px;
  cursor: pointer;
  text-align: right;
}
.sidbar_content_table_other_row th {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 700;
  color: #656565;
  line-height: 24px;
}
.sidbar_content_table_other_row td {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 24px;
  padding: 0.6rem;
}
.sidebar_content_table table tbody tr:last-child {
  border-color: white;
}
.sidebar_content_table td + td + td + td + td {
  border-right: none;
}
.sidebar_content_table th + th + th + th + th {
  border-right: none;
}

.dashboard_vechicle_table td + td + td {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.dashboard_vechicle_table th + th + th {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.dashboard_vechicle_table td + td + td + td + td + td {
  border-right: none !important;
}
.dashboard_vechicle_table th + th + th + th + th + th {
  border-right: none !important;
}

.main_screen_bootom_text {
  text-align: center;
  position: absolute;
  width: inherit;
  bottom: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}
/* @media (min-width: 576px) {
  .modal.show .modal-dialog {
    top: 42% !important;
    transform: translate(0%, -50%) !important;
  }
} */
.modal-dialog {
  top: 6%;
}
.modal-header {
  border: none;
}
.vechicle {
  margin-right: auto;
  height: 78px;
  width: 78px;
  margin: 10px auto;
}
.vechicle_modal {
  flex-direction: column;
  align-items: center;
}
.vechicle_modal_add {
  font-size: 1.4rem;
  line-height: 24px;
  font-weight: 700;
  padding-top: 10px;
}
.vechicle_modal_add p {
  margin-bottom: 0rem;
}
.vechicle_modal_form {
  width: 100%;
}
.add_vechicle_submit_button {
  padding: 1em 0;
  font-size: 0.9rem;
  font-weight: 600;
}
/* .vechicle_image_upload_container{
    /* display: flex; */
/* } */
.vechicle_image_upload {
  width: 100%;
  border: 1px dotted #3164f4;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(49, 100, 244, 0.1);
  padding: 16px 8px;
  border-radius: 8px;
}
.vechicle_image_upload_icon {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.vechicle_image_upload_text {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0px;
  padding: 0 0.5rem;
}
.vechicle_modal_form {
  border-radius: 8px;
  /* border: 2px solid black; */
  background: white;
  box-sizing: border-box;
  padding: 0rem;
  margin-left: none !important;
}
.vechicle_modal_row .form-control {
  padding: 0.8rem;
}

.modal-content {
  padding: 10px 15px 15px 15px;
  width: 90% !important;
  top: 40px;
}
.modal-header {
  padding: 10px;
}

.sidebar_content a {
  text-decoration: none !important;
  color: black;
}
/* .active div {
  /* background-color: rgba(49, 100, 244, 0.1);
    border-radius: 8px;
    color:#3164F4; */
/* }  */
.vechicle_modal_close_button {
  margin-left: auto;
  cursor: pointer;
}
.sidebar_content_round {
  border-radius: 50%;
  height: 84px;
  width: 84px;
  border: 3px solid rgb(0 55 255 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.payments_index {
  background-color: white;
  height: 80vh;
  width: 95%;
  margin-top: 30px;
  border-radius: 8px;
}
.animated_index {
  -webkit-animation: scale-in-ver-center 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}
@keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

.payment_card_payment_icon {
  background-color: #3164f4;
}
.payment_card_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.payment_card_content {
  width: 45%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment_card {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  padding: 15px 0px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.payment_card h3 {
  font-weight: 500;
  font-size: 18px;
}
.payment_card_prices {
  font-weight: 700 !important;
  font-size: 36px !important;
  text-align: center;
  padding-bottom: 20px;
}
.payment_card_button {
  width: 70% !important;
  padding: 0.8rem 0 !important;
}
.payments_index_payment_text {
  position: absolute;
  top: 16px;
  left: 40px;
  font-size: 24px;
  font-weight: 700;
}
.edit_profile_card_form_container form input {
  padding: 0.7rem 0.8rem !important;
}
.edit_profile_card_change_password {
  font-size: 15px;
  font-weight: 500;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.edit_card_content {
  width: 32%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.edit_profile_card_change_password svg {
  width: 9px;
  height: 12px;
}
.edit_profile_company_logo {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: 112px;
  width: 112px;
  margin: 10px 10px;
}
.edit_profile_company_logo img {
  height: 112px;
  width: 112px;
  object-fit: cover;
  border-radius: 50%;
}
.edit_profile_basic_details {
  font-size: 15px;
  font-weight: 500;
}
.edit_profile_edit_icon {
  background: rgba(49, 100, 244, 1);
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 3px;
  bottom: 5px;
}
/* .edit_profile_forgot_password{

} */
.logout_modal_container .modal-content {
  width: 47% !important;
}
.logout_buttons button {
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: 700;
}

.logout_modal_text {
  padding: 0px 10px;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Product Sans Regular', sans-serif !important;
}

.logout_modal {
  display: flex;
  justify-content: space-around;
  margin-top: 2.5rem !important;
}

.logout_buttons .active {
  color: red;
}

@media (min-width: 576px) {
  .arabic {
    top: 42% !important;
    transform: translate(-29%, -50%) !important;
  }
}
@media (min-width: 576px) {
  .english {
    top: 42% !important;
    transform: translate(32%, -50%) !important;
  }
}

.orderdetails_content_display {
  display: contents;
}

.toggle_password {
  position: absolute;
  top: 13px;
  right: 10px;
}
.dashboard_order_status {
  background: rgba(49, 100, 244, 0.1);
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
}
.toggle_password_right {
  position: absolute;
  top: 13px;
  left: 10px;
}

.payment_container {
  width: 70%;
}

.side_bar_active {
  background: rgba(49, 100, 244, 0.1);
  color: #3164f4;
  border-radius: 10px;
}

.enter_password_loader {
  padding: 80px 0px;
}

.bottom_bar {
  background: rgba(87, 129, 245, 0.9);
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1;
  bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.small_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  height: 45px;
  width: 45px;
}

@media (min-width: 0px) and (max-width: 767px) {
  .dashboard_main_screen_vechicle {
    width: 100% !important;
    min-height: 100vh;
  }
  .dashboard_main_screen_vechicle_table {
    margin-bottom: 100px;
  }
  .payment_card_content {
    width: 96%;
  }
  .payment_container {
    width: 100%;
  }
  .sidebar_content_table {
    width: 100% !important;
    margin-bottom: 100px;
  }
  .active_icons {
    border-radius: 100%;
    border: 2px solid white;
    background: linear-gradient(
      178deg,
      rgba(87, 129, 245, 0.9) 0%,
      rgba(87, 170, 245, 1) 86%
    );
  }
  .sidbar_content_orders {
    width: 100% !important;
  }
  .sidbar_content_container {
    padding: 10px 15px;
  }
  .company_profile_image_small {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    object-fit: cover;
  }
  .sidbar_content_table_other_row td {
    padding: 0.6rem 2.6rem;
  }

  .recent_order_table tbody td {
    width: 100%;
  }
  .payment_card h3 {
    font-weight: 500;
    font-size: 14px;
  }
  .sidebar_content_orders {
    /* padding-left: 0; */
    font-size: 1.2rem;
  }
  .order_headers {
    width: 50%;
  }
  .order_header_row {
    padding: 20px 20px;
  }
  .payments_index_payment_text {
    left: 10px;
    font-size: 18px;
  }
  .logout_modal_container .modal-content {
    width: 100% !important;
  }
  .confirmation_card {
    width: 300px;
  }
  .modal-dialog {
    top: 40%;
  }
  .forgot_password .modal-dialog {
    top: 10%;
  }
  .edit_vehicle_modal .modal-dialog {
    top: 4%;
  }

  .edit_card_content {
    width: 100%;
    border: none;
    padding: 40px 30px;
  }
  .modal-content {
    width: 100% !important;
  }

  .sidebar_content_orders {
    width: 50% !important;
  }
  .sidebar_content_orders_search_field {
    width: 50% !important;
  }
  .sidebar_content_orders_search_field .form-control {
    width: 100% !important;
  }
  .sidebar_content_view_all{
    width: 50% !important;

  }
  .order_header_row{
   padding: 30px 15px !important;
  }
  .login_container_logo img {
   width:45px;
   height:auto;
  }
  .login_container_logo {
    text-align: center;
    padding: 0.5rem;
}
.nav_text{
  font-size: 0.8rem;
}
.nav_header_icons .nav-item{
  font-size: 14px;
}
.sidebar_content_view_all_button{
font-size: 12px !important;
}
.main_screen_bootom_text{
  display: none;
 }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .sidebar_content {
    width: 30%;
  }
  .dashboard_main_screen_vechicle {
    width: 70%;
  }
  .sidebar_content_table {
    width: 100%;
  }
  .sidbar_content_container {
    padding: 10px 20px;
  }
  .payment_card_content {
    width: 90%;
  }

  .sidebar_content_orders {
    font-size: 1.3rem;
    width: 70% !important;
  }
  .sidebar_content_view_all {
    width: 30% !important;
  }

  .sidebar_content_orders_search_field .form-control {
    width: 65%;
  }
  .sidebar_content_orders_search_field {
    width: 50%;
  }

  .sidebar_content_orders {
    width: 50% !important;
  }
  .sidebar_content_orders_search_field {
    width: 50% !important;
  }
  .sidebar_content_orders_search_field .form-control {
    width: 90% !important;
  }
 
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .sidebar_content {
    width: 25%;
  }
  .dashboard_main_screen_vechicle {
    width: 75%;
  }
  .dashboard_main_screen_vechicle {
    padding-bottom: 100px;
  }

  .sidebar_content_orders_search_field .form-control {
    width: 65%;
  }
  .sidebar_content_orders_search_field {
    width: 50%;
  }

  .sidebar_content_orders {
    width: 70% !important;
  }
  .sidebar_content_view_all {
    width: 30% !important;
  }
  .sidebar_content_orders_search_field{
    width: 30% !important;

  }
  .sidebar_content_orders_search_field .form-control {
    width: 90% !important;
  }
  .payment_card_content {
    width: 80%;
  }
}
.sidebar_content_orders {
  width: 80%;
}
.sidebar_content_orders_search_field {
  width: 20%;
}

.sidebar_content_orders_search_field .form-control {
  width: 70%;
}
.order_header_row {
  padding: 30px;
}
.sidebar_content_view_all {
  width: 20%;
}

.loader_position_profile_image{
  height: 48px !important;
  width: 48px !important;
  margin-top: 0px;
}

