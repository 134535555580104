.dashboard_main_screen_vechicle {
  width: 87%;
  background: rgba(87, 129, 245, 0.1);
  position: relative;
}
.dashboard_main_screen_vechicle_table {
  border-radius: 10px;
  /* margin: 1.5rem 50px 20px; */
  margin-top:15px;
  width: 95%;
  background: white;
  overflow-x: scroll;
}
.pagination{
  width:100%;
}
.dashboard_main_screen_vechicle_table .pagination {
  flex-direction: column;
}
.pagination-controls {
  display: flex;
  justify-content: flex-end;
  width:95%;
}
.pagination-controls__button {
  cursor: pointer;
}
.dashboard_main_screen_vechicle_table_pagination_controls {
  display: flex;
  align-items: center;
  padding-right: 45px;
}
.dashboard_main_screen_vechicle_table td,
th {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.dashboard_main_screen_vechicle_table th + th + th +th +th {
  border-right: none;
}
.dashboard_main_screen_vechicle_table td + td + td +td +td{
  border-right: none;
}
.dashboard_main_screen_vechicle_table tbody tr:last-child {
  border-color: white;
}
.dashboard_main_screen_vechicle_table
  .sidbar_content_table_other_row
  tr:first-child {
  /* border-color: white; */
  width: 20%;
}
.dashboard_main_screen_vechicle_table_pagination_buttons {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 16px;
  color: #656565;
}

.dashboard_main_screen_vechicle_table_pagination_items {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 16px;
  color: #656565;
}
.dashboard_main_screen_vechicle_table_pagination_page_size {
  padding: 0px 10px;
}
.dashboard_main_screen_vechicle_table_pagination_page_size input {
  height: 35px;
  width: 42px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 16px;
  color: #656565;
}
.sidebar_content_view_all{
  padding-right: 0px;

}
.sidebar_content_view_all_button {
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  background-color: #3164f4;
  border-radius: 10px;
  color: white;
  outline: none;
  border: none;
}

.vechicle_image_upload_container {
  cursor: pointer;
}
.orders_table_icon {
  height: 40px;
  width: 40px;
  border-radius: 50% !important;
}
.cursor-pointer{
  cursor: pointer;
}

.company_profile_image{
  height:94px;
  width:94px;
  object-fit: contain;
}

.vehicle_number_plate{
position: relative;
}
.vehicle_number_plate img{
  width:362px;
  height: 200px;
  object-fit: cover;
  }
.cross_icon{
  position: absolute;
  right: 3px;
  top: -13px;
}

.error_image_not_found img{
  width: 100%;
  height: 290px;
  object-fit: cover;
}

.loader_position{
  margin: auto;
  margin-top: 20px;
}
.empty_table_data{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 30px;
}
.signup_drop_down_menu{
  position: absolute;
  width: 23% !important;
  padding: 0.8rem 1.9rem 0.8rem 0.6rem;
}

/* .countries_code_select{
  width:
} */

select, option {
  width:150px;
}


.countries_code_select{
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

.signup_phone_number_left{
  margin-left: auto;

}
.signup_phone_number_right{
  margin-right: auto;

}

.loader-line {
  width: 200px;
  height: 8px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 100px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 44px;
  width: 68%;
  background-color: #191919;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
      left: -40%;
  }
  50% {
      left: 20%;
      width: 80%;
  }
  100% {
      left: 100%;
      width: 100%;
  }
}